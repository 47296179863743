<template>
  <BaseDialog
    :show="true"
    width="600px"
    :title="title"
    btn1="確認"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="操作類型" prop="editType">
          <BaseElRadioGroup v-model="formData.editType">
            <BaseElRadio label="add">增加</BaseElRadio>
            <BaseElRadio label="minus">扣除</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>
        <BaseElFormItem label="數量" prop="amount">
          <BaseElInput v-model="formData.amount" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { exportFormatOptions } from '@/config/export'
import { couponTypeConfig } from '@/config/coupon'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules, isDigitRules } from '@/validation'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'OpentixCouponEditModal',
  components: { BaseDialog },
  props: { title: String },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    initFormData({
      editType: 'add',
      amount: null,
    })
    const formRules = {
      editType: [noEmptyRules()],
      amount: [noEmptyRules(), isDigitRules()],
    }

    const onConfirm = () => {
      formUtils.checkForm(formRef.value).then((valid) => {
        if (valid) emit('confirm', formData)
      })
    }
    return {
      onConfirm,
      formData,
      exportFormatOptions,
      formRef,
      formRules,
      couponTypeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
