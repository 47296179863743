<template>
  <div class="opentix-setting-block side-highlight-container">
    <p class="card-title">優惠碼設定</p>
    <p class="hint">優惠碼將於優惠券建立成功後，由系統自動生成</p>
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem v-if="get(configData, 'id')" label="優惠碼" prop="code">
        <p class="text-gray-80">{{ formData.code }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="優惠碼初始數量" prop="firstSentLimit">
        <BaseElInput
          v-model="formData.firstSentLimit"
          placeholder="請輸入優惠碼數量，輸入 -1 則代表無庫存上限"
          type="text"
          :disabled="!!get(configData, 'id')"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="get(configData, 'id')" label="優惠碼剩餘數量" prop="lastCount">
        <p class="text-gray-80">
          {{ formData.lastCount === '無上限' ? formData.lastCount : formData.lastCount - formData.sentCount }}
          <span v-if="!isEmpty(editData)">{{ `(儲存後變動： ${editData.editType === 'add' ? '+':'-'}${editData.amount})` }}</span>
        </p>
      </BaseElFormItem>
    </BaseElForm>
    <BaseElButton v-if="get(configData, 'id') && formData.lastCount !== '無上限'" :disabled="get(configData, 'extraStatus.opentixIsDisabled')" class="btn" plain @click="modal.couponCountEdit = true">編輯數量</BaseElButton>
    <OpentixAmountEditModal
      v-if="modal.couponCountEdit"
      title="編輯優惠碼數量"
      @close="modal.couponCountEdit = false"
      @confirm="onCouponCountEditSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch, nextTick, ref, reactive } from 'vue'
import { get, set, isEmpty } from 'lodash'
import { useBaseForm, onFormRulesChangeClearValidate, mappingSyncFormData, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'
import OpentixAmountEditModal from './OpentixAmountEditModal.vue'

export default defineComponent({
  name: 'OpentixSettingBlock',
  components: {
    OpentixAmountEditModal,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: [],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    // const avatarChanged = ref(false)
    // const uploadDialog = ref(false)
    const modal = reactive({
      couponCountEdit: false,
    })
    /** 初始化表單值 */
    initFormData({
      code: null,
      firstSentLimit: null,
      lastCount: null,
      sentCount: null,
    })
    const editData = ref({})
    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        firstSentLimit: [noEmptyRules()],
      }
      // handle custom rules here
      // if (...) rules.xxx = [...]
      return rules
    })

    /** 同步表單資料 */
    const syncFormData = (data) => {
      set(formData, 'code', get(data, 'extraStatus.opentixEcouponId'))
      set(formData, 'firstSentLimit', get(data, 'firstSentLimit') || '無上限')
      set(formData, 'lastCount', get(data, 'firstSentLimit') === null ? '無上限' : (get(data, 'sentLimit') || get(data, 'firstSentLimit')))
      set(formData, 'sentCount', get(data, 'sentCount'))
      // -> 如果有原始資料 key 與 formData 的 key 命名不同時：
      // mappingSyncFormData({
      //   'extraStatus["opentixEcouponId"]': 'code',
      //   // username: 'name',
      // }, data, formData)
      // -> 如果都一樣，可以簡單使用：

      // simpleSyncFormData(data, formData)
    }

    const compactData = computed(() => {
      const data = {
        firstSentLimit: get(formData, 'firstSentLimit'),
        lastCount: get(formData, 'lastCount'),
        editData: editData.value,
      }
      console.log(data)
      return data
    })

    watch(compactData, () => {
      FormsContext.setFormData('setting', compactData.value)
    })

    // 需要時再使用：
    // onFormRulesChangeClearValidate(formRef, formRules)
    const onCouponCountEditSubmit = (data) => {
      editData.value = data
      modal.couponCountEdit = false
    }
    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('setting', formRef.value)
      if (get(props.configData, 'id')) {
        syncFormData(props.configData)
      }
    })

    return {
      formRef,
      formData,
      formRules,
      modal,
      onCouponCountEditSubmit,
      get,
      editData,
      isEmpty,
    }
  },
})
</script>

  <style lang="postcss" scoped>
  .opentix-setting-block {
    padding-left: 29px !important;
  }
  .card-title {
    @apply mb-[10px]
  }
.hint {
    @apply text-gray-80 text-sm mb-[20px] font-medium
}
  </style>
