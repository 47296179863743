<template>
  <div class="opentix-info-block side-highlight-container">
    <p class="card-title">{{ pageTitle }}</p>
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem
        v-for="field in formFields"
        :key="field.prop"
        :label="field.label"
        :prop="field.prop"
      >
        <BaseElInput
          v-if="field.type !== 'upload'"
          v-model="formData[field.prop]"
          :placeholder="field.placeholder"
          :type="field.type"
          :rows="field.rows"
          :maxlength="field.maxlength"
          :show-word-limit="field.showWordLimit"
          :disabled="field.disabled"
        />
        <UploadButton
          v-else
          cyUploadBtn="ticket-img-upload-btn"
          cyUploadedImg="ticket-img-uploaded"
          :img="formData.avatar"
          :isAvatar="true"
          :disabled="field.disabled"
          @change="loadImg"
        />
      </BaseElFormItem>
    </BaseElForm>
    <ImageCropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch, nextTick, ref } from 'vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { get, set } from 'lodash'
import { useBaseForm, onFormRulesChangeClearValidate, mappingSyncFormData, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'

export default defineComponent({
  name: 'OpentixInfoBlock',
  components: {
    UploadButton,
    ImageCropper,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: [],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    const avatarChanged = ref(false)
    const uploadDialog = ref(false)
    const formFields = [
      { label: '優惠券名稱', prop: 'name', placeholder: '請輸入優惠券名稱', maxlength: 20, showWordLimit: true, type: 'text', disabled: get(props.configData, 'extraStatus.opentixIsDisabled') },
      { label: '圖片', prop: 'image', type: 'upload', disabled: get(props.configData, 'extraStatus.opentixIsDisabled') },
      { label: '優惠券說明', prop: 'description', placeholder: '請輸入', type: 'textarea', rows: 3, disabled: get(props.configData, 'extraStatus.opentixIsDisabled') },
      { label: '店家備註', prop: 'notice', placeholder: '請輸入', type: 'textarea', rows: 3, disabled: get(props.configData, 'extraStatus.opentixIsDisabled') },
    ]
    /** 初始化表單值 */
    initFormData({
      name: null,
      image: null,
      avatar: null,
      description: null,
      notice: null,
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        description: [noEmptyRules()],
      }
      // handle custom rules here
      // if (...) rules.xxx = [...]
      return rules
    })
    const pageTitle = computed(() => {
      if (get(props.configData, 'extraStatus.opentixIsDisabled')) return '查看OPENTIX優惠券'
      else if (get(props.configData, 'id')) return '編輯OPENTIX優惠券'
      else return '新增OPENTIX優惠券'
    })
    /** 同步表單資料 */
    const syncFormData = (data) => {
      set(formData, 'name', data.name)
      set(formData, 'description', get(data, 'extraSetting.openTixConfig.description'))
      set(formData, 'notice', data.notice)
      set(formData, 'avatar', data.Image)
      // -> 如果有原始資料 key 與 formData 的 key 命名不同時：
      // mappingSyncFormData({
      //   name: 'name',
      //   // description: 'extraSetting.openTixConfig.description',
      //   'extraSetting.openTixConfig.description': 'description',
      //   notice: 'notice',
      //   image: 'image',
      // }, data, formData)
      // -> 如果都一樣，可以簡單使用：
      // simpleSyncFormData(data, formData)
    }

    const compactData = computed(() => {
      const data = {
        name: get(formData, 'name'),
        image: get(formData, 'avatar'),
        description: get(formData, 'description'),
        notice: get(formData, 'notice'),
      }
      return data
    })

    watch(compactData, () => {
      FormsContext.setFormData('info', compactData.value)
    })

    // 需要時再使用：
    // onFormRulesChangeClearValidate(formRef, formRules)
    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('info', formRef.value)
      if (get(props.configData, 'id')) syncFormData(props.configData)
    })

    return {
      formRef,
      formData,
      formRules,
      loadImg,
      uploadDialog,
      getImage,
      formFields,
      pageTitle,
    }
  },
})
</script>

<style lang="postcss" scoped>
.opentix-info-block {
  padding-left: 29px !important;
}
.form-relative-label {
  @apply relative;
}
.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}
</style>
