export const couponStatusConfig = {
  all: { label: '全部', value: 'all', tagType: 'action' },
  open: { label: '未使用', value: 'open', tagType: 'action' },
  complete: { label: '已使用', value: 'complete', tagType: 'info' },
  overdue: { label: '已過期', value: 'overdue', tagType: 'danger' },
}

export const filterTimeCategoryConfig = {
  createdAt: { label: '票券發送日期', value: 'createdAt' },
  usedAt: { label: '票券使用日期', value: 'usedAt' },
  expAt: { label: '票券到期日期', value: 'expAt' },
}

export const orderByOptionsConfig = {
  createdAt: { label: '發送日期 近 -> 遠', value: 'createdAt' },
  usedAt: { label: '使用日期 近 -> 遠', value: 'usedAt' },
  expAt: { label: '到期日期 近 -> 遠', value: 'expAt' },
}

export const couponTypeConfig = {
  coupon: { label: '使用券', value: 'coupon' },
  exchange: { label: '兌換券', value: 'exchange' },
  opentix: { label: 'OPENTIX 優惠券', value: 'opentix' },
  pospal: { label: '瑞乘優惠券', value: 'pospal' },
}

export const couponOriginTypeConfig = {
  groupPushMessage: { label: '分群推播', value: 'groupPushMessage' },
  admin: { label: '後台新增', value: 'admin' },
  lottery: { label: '抽獎活動', value: 'lottery' },
  memberStorePointExchange: { label: '點數兌換', value: 'memberStorePointExchange' },
  memberStore: { label: '商城新增', value: 'memberStore' },
  eventPlaybook: { label: '系統行銷活動發放', value: 'eventPlaybook' },
  rewardDistribution: { label: '獎勵發放活動', value: 'rewardDistribution' },
}
